<template>
    <v-card outlined>
        <v-card-title class="pl-7 block-title">보이는 ARS 사용</v-card-title>
        <v-card-text class="pl-7 pb-0">
            <v-row>
                <v-col cols="12" class="pt-3 pb-0 font-weight-bold">
                <v-switch inset color="#4E87DD" v-model="wvUseYn" true-value="Y" false-value="N">
                    <template v-slot:prepend>
                        <span style="color:#333333; font-size:20px; width:100px;">기능 사용</span>
                    </template>
                </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-5">
                <p style="color:#555555; font-size:18px;">전화한 고객에게 ARS 시나리오와 아래 소개정보를 화면으로 제공합니다.</p>
                <p style="color:#999999; font-size:16px;">
                    - 고객이 CallGate사의 SDK를 보유한 경우 화면이 제공됩니다. (SDK보급률: 약 40%)<br/>
                    - OFF 시 음성으로만 안내합니다.<br/>
                    - iOS는 Apple 정책상 보이는 ARS가 제공되지 않습니다.
                </p>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>    
</template>
<script>
export default {
    name: 'svc_wv_use',
    components: {
    },
    data: () => ({
    }),
    created () { 
    },  
    mounted () { 
    },
    computed: {
        wvUseYn: { 
            get: function() { 
                return this.$store.state.svcWv.wvUseYn
            },
            set: function(v) { 
                this.$store.commit('svcWv/setWvUseYn', v)
            }
        }
    },  
    methods: { 
    }
}</script>